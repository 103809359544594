@import '../../style/mixins/index';

// mixins for grid system
// ------------------------

.loop-grid-columns(@index, @class) when (@index > 0) {
  .@{ant-prefix}-col@{class}-@{index} {
    display: block;
    flex: 0 0 percentage((@index / @grid-columns));
    min-width: 0;
    max-width: percentage((@index / @grid-columns));
    max-width: calc(percentage((@index / @grid-columns)) - ~'var(--column-gap)');
  }
  .@{ant-prefix}-col@{class}-push-@{index} {
    left: percentage((@index / @grid-columns));
  }
  .@{ant-prefix}-col@{class}-pull-@{index} {
    right: percentage((@index / @grid-columns));
  }
  .@{ant-prefix}-col@{class}-offset-@{index} {
    margin-left: percentage((@index / @grid-columns));
  }
  .@{ant-prefix}-col@{class}-order-@{index} {
    order: @index;
  }
  .loop-grid-columns((@index - 1), @class);
}

.loop-grid-columns(@index, @class) when (@index = 0) {
  .@{ant-prefix}-col@{class}-@{index} {
    display: none;
  }
  .@{ant-prefix}-col-push-@{index} {
    left: auto;
  }
  .@{ant-prefix}-col-pull-@{index} {
    right: auto;
  }
  .@{ant-prefix}-col@{class}-push-@{index} {
    left: auto;
  }
  .@{ant-prefix}-col@{class}-pull-@{index} {
    right: auto;
  }
  .@{ant-prefix}-col@{class}-offset-@{index} {
    margin-left: 0;
  }
  .@{ant-prefix}-col@{class}-order-@{index} {
    order: 0;
  }
}

.make-grid(@class: ~'') {
  .loop-grid-columns(@grid-columns, @class);
}
